"use strict";

/*global  jQuery*/
jQuery(function ($) {
$(function () {

  //読み込みが完了
  $(window).on('load',function () { 
    console.log('jQuery Page Loaded.');  
  });
      $('.close-nav-foot').delay(1000).queue(function(){
        $(this).removeClass('close-nav-foot');
      });

  // 再検索用の項目をスマホ時は非表示にする ※リサイズは非対応
  if(window.innerWidth < 1000) {
    $('#search-input').removeClass('show');
    $('#search-input-toggle').attr({
      'aria-expanded': 'false'
    });
  }


  //サイドメニューの開閉
  $(document).on('click','#btn-nav-main,#nav-main-background',function(){
    $('#nav-main,#nav-main-background').toggleClass('open');
    // $('body').toggleClass('modal-open');
  });
  $(document).on('touchend','#nav-main-background',function(){
    $('#nav-main,#nav-main-background').toggleClass('open');
    // $('body').toggleClass('modal-open');
  });

  //フッターメニューの開閉
  $(document).on('click','#btn-foot-update',function(){
    $('#nav-foot').addClass('open');
    // $('body').addClass('modal-open');
  });
  $(document).on('click','#btn-foot-close',function(){
    $('#nav-foot').removeClass('open');
    // $('body').removeClass('modal-open');
    $('.nav-link,.nav-pan').removeClass('active');
  });

  $('a[data-toggle="tab"]').on('shown.bs.tab', function (e) {
    if($(this).hasClass('guest')){
      $('#joinModal').modal('show');
      $(e.target).removeClass('active'); // newly activated tab
      $(e.relatedTarget).tab('show'); // previous active tab
    } else {
      $('#nav-foot').addClass('open');
      // $('body').addClass('modal-open');
    }
  })

  //検索タブ
  $(document).on('click','#search-tabs_front .search-tab:not(.guest)',function(){
      var wkTarget=$(this).attr('data-target')
      $('#search-tabs_front .search-tab').removeClass('active');
      $('#search-tabs_front .search-tab-contents').removeClass('show');
      $(this).addClass('active');
      $(wkTarget).addClass('show');
  });
  //検索タブ
  $(document).on('click','#search-tabs_foot .search-tab:not(.guest)',function(){
      var wkTarget=$(this).attr('data-target')
      $('#search-tabs_foot .search-tab').removeClass('active');
      $('#search-tabs_foot .search-tab-contents').removeClass('show');
      $(this).addClass('active');
      $(wkTarget).addClass('show');
  });

  //鍵付きリンク モーダルウィンドウ表示
  $(document).on('click','.guest',function(){
    $('#joinModal').modal('show');
    return false;
  });

});
});
